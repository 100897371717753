import { useEffect, useState, useMemo, useCallback } from 'react';
import omit from 'lodash/omit';
import uniq from 'lodash/identity';
import {
  FieldError,
  UseFormRegister,
  Controller,
  Control,
  useWatch,
  UseFormSetValue,
  UseFormResetField,
  UseFormClearErrors,
} from 'react-hook-form';
import { FormContainer, FieldGroupLabel } from 'ui';
import RadioField from 'ui/form/RadioField';
import SelectField from 'ui/form/SelectField';
import DatePickerFieldDobleView from 'ui/form/DatePickerFieldDobleView';
import TextField from 'ui/form/TextField';
import {
  ConsumptionGroups,
  TariffGroupModalityType,
} from 'tariffGroups/models/tariffGroup';
import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';
import { Option } from 'ui/components/form/SelectInput';

export type FormFields = {
  energyUsageTariffOnPeak: string;
  energyUsageTariffOffPeak: string;
  energyDistributionServiceTariffOnPeak: string;
  energyDistributionServiceTariffOffPeak: string;
  energyTariff: string;
  energyDistributionServiceTariff: string;
  demandCostAll: string;
  demandCostOnPeak: string;
  demandCostOffPeak: string;
  tariffGroupConsumptionGroup: ConsumptionGroups;
  tariffGroupModality: TariffGroupModalityType;
  tariffGroupValidity: [Date, Date];
  powerDistributionUnitId: number;
};

type Field =
  | 'energyUsageTariffOnPeak'
  | 'energyUsageTariffOffPeak'
  | 'energyDistributionServiceTariffOnPeak'
  | 'energyDistributionServiceTariffOffPeak'
  | 'energyTariff'
  | 'energyDistributionServiceTariff'
  | 'demandCostAll'
  | 'demandCostOnPeak'
  | 'demandCostOffPeak'
  | 'tariffGroupConsumptionGroup'
  | 'tariffGroupModality'
  | 'tariffGroupValidity'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'energyUsageTariffOnPeak',
  'energyUsageTariffOffPeak',
  'energyDistributionServiceTariffOnPeak',
  'energyDistributionServiceTariffOffPeak',
  'energyTariff',
  'energyDistributionServiceTariff',
  'demandCostAll',
  'demandCostOnPeak',
  'demandCostOffPeak',
  'tariffGroupConsumptionGroup',
  'tariffGroupModality',
  'tariffGroupValidity',
  'powerDistributionUnitId',
];

export type FormErrors = {
  energyUsageTariffOnPeak?: FieldError | undefined;
  energyUsageTariffOffPeak?: FieldError | undefined;
  energyDistributionServiceTariffOnPeak?: FieldError | undefined;
  energyDistributionServiceTariffOffPeak?: FieldError | undefined;
  energyTariff?: FieldError | undefined;
  energyDistributionServiceTariff?: FieldError | undefined;
  demandCostAll?: FieldError | undefined;
  demandCostOnPeak?: FieldError | undefined;
  demandCostOffPeak?: FieldError | undefined;
  tariffGroupConsumptionGroup?: FieldError | undefined;
  tariffGroupModality?: FieldError | undefined;
  tariffGroupValidity?: [FieldError?, FieldError?] | undefined;
  powerDistributionUnitId?: FieldError | undefined;
};

const consumptionGroups = ['A', 'B'].map((group) => ({
  label: (
    <>
      Grupo <span className="font-bold">{group}</span>
    </>
  ),
  value: group,
}));

export const TRANSLATE_TARIFF_GROUP_MODALITY_TYPE_TO_PT = {
  BLUE: 'AZUL',
  GREEN: 'VERDE',
  CONVENTIONAL: 'CONVENCIONAL',
};

export default function TariffGroupFields({
  errors,
  register,
  control,
  setValue,
  resetField,
  powerDistributionUnits,
  clearErrors,
  disablePowerDistributionUnit,
  powerDistributionUnitValue,
}: {
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  control: Control<FormFields>;
  setValue: UseFormSetValue<FormFields>;
  resetField: UseFormResetField<FormFields>;
  clearErrors: UseFormClearErrors<FormFields>;
  disablePowerDistributionUnit?: boolean;
  powerDistributionUnitValue?: number;
}) {
  const [consumptionGroup, modality] = useWatch({
    control,
    name: ['tariffGroupConsumptionGroup', 'tariffGroupModality'],
  });
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions(
        powerDistributionUnits?.map((powerDistributionUnit) => ({
          key: powerDistributionUnit.id,
          value: powerDistributionUnit.powerDistributionUnitLegalName,
        })) || []
      ),
    [powerDistributionUnits]
  );

  const bConsumptionGroupSelected = consumptionGroup === ConsumptionGroups.B;

  useEffect(() => {
    if (bConsumptionGroupSelected) {
      setValue('tariffGroupModality', TariffGroupModalityType.CONVENTIONAL);
      clearErrors('tariffGroupModality');
    } else {
      resetField('tariffGroupModality');
    }
  }, [bConsumptionGroupSelected, clearErrors, resetField, setValue]);

  const noConsumptionGroupSelected = !consumptionGroup;
  const aConsumptionGroupSelected = consumptionGroup === ConsumptionGroups.A;
  const noModalitySelected = !modality;
  const AZULModalitySelected = modality === TariffGroupModalityType.BLUE;
  const VERDEModalitySelected = modality === TariffGroupModalityType.GREEN;

  const disabledFields = useMemo(() => {
    const disabledMap = {
      'tariffGroupModality energyUsageTariffOnPeak energyUsageTariffOffPeak energyDistributionServiceTariffOnPeak energyDistributionServiceTariffOffPeak':
        noConsumptionGroupSelected,
      'demandCostAll demandCostOnPeak demandCostOffPeak': noModalitySelected,
      demandCostAll: AZULModalitySelected,
      'demandCostOnPeak demandCostOffPeak': VERDEModalitySelected,
      'energyUsageTariffOffPeak energyDistributionServiceTariffOffPeak demandCostAll demandCostOffPeak demandCostOnPeak':
        bConsumptionGroupSelected,
    };
    return uniq(
      Object.entries(disabledMap)
        .filter(([_key, value]) => value)
        .map(([key]) => key)
        .join(' ')
        .split(' ')
    );
  }, [
    bConsumptionGroupSelected,
    AZULModalitySelected,
    VERDEModalitySelected,
    noConsumptionGroupSelected,
    noModalitySelected,
  ]);

  const isDisabled = useCallback(
    (field) => disabledFields.includes(field),
    [disabledFields]
  );

  const modalities = useMemo(
    () =>
      [
        TariffGroupModalityType.BLUE,
        TariffGroupModalityType.GREEN,
        TariffGroupModalityType.CONVENTIONAL,
      ]
        .filter((group) => {
          if (aConsumptionGroupSelected) {
            return (
              group === TariffGroupModalityType.BLUE ||
              group === TariffGroupModalityType.GREEN
            );
          }
          if (bConsumptionGroupSelected) {
            return group === TariffGroupModalityType.CONVENTIONAL;
          }
          return true;
        })
        .map((group) => ({
          key: group,
          value: TRANSLATE_TARIFF_GROUP_MODALITY_TYPE_TO_PT[group],
        })),
    [aConsumptionGroupSelected, bConsumptionGroupSelected]
  );

  return (
    <FormContainer>
      <div className="col-span-full">
        <Controller
          render={({ field }) => (
            <RadioField
              required
              {...omit(field, 'ref')}
              error={errors.tariffGroupConsumptionGroup?.message}
              label="Grupo"
              options={consumptionGroups}
            />
          )}
          name="tariffGroupConsumptionGroup"
          control={control}
          rules={{ required: 'O Grupo de Consumo é obrigatório' }}
        />
      </div>
      <Controller
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref', 'value')}
            error={errors.powerDistributionUnitId?.message}
            label="Concessionária"
            emptyOptionLabel="Escolha uma concessionária"
            id="powerDistributionUnitId"
            value={
              powerDistributionUnitValue
                ? powerDistributionUnitValue
                : field.value
                ? field.value
                : undefined
            }
            options={powerDistributionUnitOptions}
            disabled={disablePowerDistributionUnit}
          />
        )}
        name="powerDistributionUnitId"
        control={control}
        // rules={{ required: 'A Concessionária é obrigatório' }}
      />
      <Controller
        render={({ field }) => (
          <SelectField
            required
            {...omit(field, 'ref')}
            error={errors.tariffGroupModality?.message}
            label="Modalidade"
            emptyOptionLabel="Escolha uma modalidade"
            id="tariffGroupModality"
            options={modalities}
            disabled={isDisabled('tariffGroupModality')}
          />
        )}
        name="tariffGroupModality"
        control={control}
        rules={{ required: 'A Modalidade é obrigatória' }}
      />
      <Controller
        render={({ field }) => (
          <DatePickerFieldDobleView
            required
            {...omit(field, 'ref')}
            error={{
              startDateInput:
                (errors?.tariffGroupValidity as unknown as FieldError) &&
                'O Início da validade é obrigatório',
              endDateInput:
                (errors?.tariffGroupValidity as unknown as FieldError) &&
                'O Fim da validade é obrigatório',
            }}
            id="tariffGroupValidity"
            label="Inicio da validade"
            endLabel="Fim da validade"
            selectRange
          />
        )}
        name="tariffGroupValidity"
        control={control}
        rules={{
          required: 'Campo obrigatório',
        }}
      />
      <div className="flex flex-col">
        <FieldGroupLabel className="col-span-2 gap-x-0">TE (R$/MWh)</FieldGroupLabel>
        <div className="grid grid-cols-2 gap-x-7">
          <TextField
            required={!isDisabled('energyUsageTariffOnPeak')}
            error={errors?.energyUsageTariffOnPeak?.message}
            label="Ponta"
            id="energyUsageTariffOnPeak"
            {...register('energyUsageTariffOnPeak', {
              required: {
                value: !isDisabled('energyUsageTariffOnPeak'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('energyUsageTariffOnPeak')}
            data-testid="te-ponta"
          />
          <TextField
            required={!isDisabled('energyUsageTariffOffPeak')}
            error={errors?.energyUsageTariffOffPeak?.message}
            label="Fora Ponta"
            id="energyUsageTariffOffPeak"
            {...register('energyUsageTariffOffPeak', {
              required: {
                value: !isDisabled('energyUsageTariffOffPeak'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('energyUsageTariffOffPeak')}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <FieldGroupLabel className="col-span-2 gap-x-0">
          TUSD (R$/MWh)
        </FieldGroupLabel>
        <div className="grid grid-cols-2 gap-x-6">
          <TextField
            required={!isDisabled('energyDistributionServiceTariffOnPeak')}
            error={errors?.energyDistributionServiceTariffOnPeak?.message}
            label="Ponta"
            id="energyDistributionServiceTariffOnPeak"
            {...register('energyDistributionServiceTariffOnPeak', {
              required: {
                value: !isDisabled('energyDistributionServiceTariffOnPeak'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('energyDistributionServiceTariffOnPeak')}
            data-testid="tusd-ponta"
          />
          <TextField
            required={!isDisabled('energyDistributionServiceTariffOffPeak')}
            error={errors?.energyDistributionServiceTariffOffPeak?.message}
            label="Fora Ponta"
            id="energyDistributionServiceTariffOffPeak"
            {...register('energyDistributionServiceTariffOffPeak', {
              required: {
                value: !isDisabled('energyDistributionServiceTariffOffPeak'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('energyDistributionServiceTariffOffPeak')}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <FieldGroupLabel>Tarifa de Demanda (R$/kW)</FieldGroupLabel>
        <div className="grid grid-cols-3 gap-x-5">
          <TextField
            required={!isDisabled('demandCostAll')}
            error={errors?.demandCostAll?.message}
            label="Tarifa base"
            id="demandCostAll"
            {...register('demandCostAll', {
              required: {
                value: !isDisabled('demandCostAll'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('demandCostAll')}
          />
          <TextField
            required={!isDisabled('demandCostPeak')}
            error={errors?.demandCostOnPeak?.message}
            label="Ponta"
            id="demandCostOnPeak"
            {...register('demandCostOnPeak', {
              required: {
                value: !isDisabled('demandCostOnPeak'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('demandCostOnPeak')}
          />
          <TextField
            required={!isDisabled('demandCostOffPeak')}
            error={errors?.demandCostOffPeak?.message}
            label="Fora Ponta"
            id="demandCostOffPeak"
            {...register('demandCostOffPeak', {
              required: {
                value: !isDisabled('demandCostOffPeak'),
                message: 'Este valor é obrigatório',
              },
            })}
            placeholder="0.00"
            disabled={isDisabled('demandCostOffPeak')}
          />
        </div>
      </div>
    </FormContainer>
  );
}

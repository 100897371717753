import { gql } from '@apollo/client';
import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';

interface CommercialAgreementConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: CommercialAgreement[];
}

export interface CommercialAgreementsList {
  commercialAgreements: CommercialAgreementConnection;
}

export const CommercialAgreementsTypename = 'CommercialAgreementConnection';

export const COMMERCIAL_AGREEMENTS_QUERY = gql`
  query commercialAgreements($after: String, $before: String, $pageSize: Int) {
    commercialAgreements(after: $after, before: $before, pageSize: $pageSize) {
      entries {
        id
        commercialAgreementStartValidity
        commercialAgreementHasPisCofins
        commercialAgreementHasExtraRemuneration
        commercialAgreementReceiptRule
        commercialAgreementLeaseValue
        commercialAgreementOemValue
        commercialAgreementHasOem
        commercialAgreementDiscountMin
        commercialAgreementDiscountMax
        commercialAgreementBaseCostPerCreditIcms
        commercialAgreementBaseCostPerCredit
        generationUnit {
          id
          generationUnitLegalName
        }
      }
      beforeCursor
      afterCursor
    }
  }
`;

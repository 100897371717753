import { MenuSidebar, NotionLinks } from 'dashboard/models/navigation';
import {
  MdAccountBalance,
  MdAccountBalanceWallet,
  MdWifiTethering,
  MdAssignmentInd,
  MdStore,
  MdOutlineBookmark,
  MdFlag,
  MdOutlineSignalCellularAlt,
  MdFolderShared,
  MdAssignment,
  MdStyle,
  MdWatchLater,
  MdTune,
  MdEmail,
} from 'react-icons/md';
import { FaUserFriends } from 'react-icons/fa';
import { ImHammer2 } from 'react-icons/im';
import { HiLightBulb } from 'react-icons/hi';
import { RiCalendar2Fill, RiSwapFill } from 'react-icons/ri';
import { AiFillFileAdd } from 'react-icons/ai';

export const menuSidebar: MenuSidebar = [
  {
    name: 'Usuário interno',
    subtitle: 'USUÁRIO INTERNO',
    to: '/dashboard/internal-users',
    disabled: false,
    icon: FaUserFriends,
  },
  {
    name: 'Entidades de negócio',
    subtitle: 'ENTIDADES DE NEGÓCIO',
    icon: MdAccountBalance,
    disabled: false,
    submenu: [
      {
        name: 'Unidade geradora',
        to: '/dashboard/business-entities/generation-units',
        icon: MdWifiTethering,
        disabled: false,
      },
      {
        name: 'Cooperativa',
        to: '/dashboard/business-entities/cooperatives',
        icon: MdAccountBalance,
        disabled: false,
      },
      {
        name: 'Cooperado',
        to: '/dashboard/business-entities/cooperative-members',
        icon: MdAssignmentInd,
        disabled: false,
      },
      {
        name: 'Unidade consumidora',
        to: '/dashboard/business-entities/consumer-units',
        icon: MdStore,
        disabled: false,
      },
      {
        name: 'Concessionária',
        to: '/dashboard/business-entities/power-distribution-units',
        icon: HiLightBulb,
        disabled: false,
      },
    ],
  },
  {
    name: 'Grupos e contatos',
    subtitle: 'GRUPS E CONTATOS',
    to: '/dashboard/group-contacts',
    disabled: true,
    icon: MdEmail,
  },
  {
    name: 'Faturamento NEX',
    subtitle: 'FATURAMENTO NEX',
    icon: MdAccountBalanceWallet,
    disabled: false,
    submenu: [
      {
        name: 'Rateio',
        to: '/dashboard/billing-cycle/apportionment-cycle',
        icon: MdTune,
        disabled: false,
      },
      {
        name: 'Cobranças',
        to: '/dashboard/billing-cycle/charges',
        icon: RiSwapFill,
        disabled: true,
      },
      {
        name: 'Creditação',
        to: '/dashboard/billing-cycle/bill-data',
        icon: MdStyle,
        disabled: true,
      },
      {
        name: 'Ciclos de locação',
        to: '/dashboard/billing-cycle/lease-cycles',
        icon: MdWatchLater,
        disabled: true,
      },
      {
        name: 'Transferências',
        to: '/dashboard/billing-cycle/transfers',
        icon: MdAssignment,
        disabled: true,
      },
      {
        name: 'Conta financeira',
        to: '/dashboard/billing-cycle/financial-accounts',
        icon: MdFolderShared,
        disabled: false,
      },
      {
        name: 'Acordo comercial',
        to: `/dashboard/billing-cycle/commercial-agreements`,
        icon: AiFillFileAdd,
        disabled: false,
      },
    ],
  },
  {
    name: 'Tarifas e tributos',
    subtitle: 'TARIFAS E TRIBUTOS',
    icon: ImHammer2,
    disabled: true,
    submenu: [
      {
        name: 'Tarifas de energia',
        to: '/dashboard/taxes-fees/tariff-groups',
        icon: MdOutlineBookmark,
        disabled: true,
      },
      {
        name: 'Bandeiras',
        to: '/dashboard/taxes-fees/tariff-flags',
        icon: MdFlag,
        disabled: true,
      },
      {
        name: 'ICMS',
        to: '/dashboard/taxes-fees/icms-taxes',
        icon: MdOutlineSignalCellularAlt,
        disabled: true,
      },
      {
        name: 'PIS/COFINS',
        to: '/dashboard/taxes-fees/pis-cofins-taxes',
        icon: RiCalendar2Fill,
        disabled: true,
      },
    ],
  },
];

export const notionLinks: NotionLinks = [
  {
    id: 1,
    name: 'Notas de lançamento: Beta v2',
    to: 'https://nexpedia.notion.site/Notas-de-lan-amento-Beta-v2-do-IntraNEX-v2-1ed5457a7cf54d778c2a68f61fb3a894',
  },
  {
    id: 2,
    name: 'Tutorial: Passo a passo para cadastros',
    to: 'https://nexpedia.notion.site/Passo-a-passo-para-cadastro-de-entidades-e-clientes-no-IntraNEX-v2-edeb4a5f04084a7799a48a8699858d8a',
  },
  {
    id: 3,
    name: 'O que são as entidades de negócio?',
    to: 'https://nexpedia.notion.site/O-que-s-o-as-entidades-de-neg-cio-no-Intranex-v2-8f14ec1db7804d06a9b10e906767bfd5',
  },
  {
    id: 4,
    name: 'O que são usuário internos?',
    to: 'https://nexpedia.notion.site/O-que-s-o-usu-rio-internos-do-IntraNEX-v2-6a7bf9c2dd694bac80ced63cb0a6d394',
  },
  {
    id: 5,
    name: 'O que são as contas financeiras?',
    to: 'https://nexpedia.notion.site/O-que-s-o-as-contas-financeiras-do-Intranex-v2-eef9dee50afd430da0c9b840133e47cd',
  },
];

import { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TariffGroupItem from 'tariffGroups/components/TariffGroupItem';
import { TariffGroupProps } from 'tariffGroups/models/tariffGroup';
import { TRANSLATE_TARIFF_GROUP_MODALITY_TYPE_TO_PT } from '../form/TariffGroupFields';

type Props = {
  tariffGroup: TariffGroupProps;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

const TARIFF_GROUP_VALIDITY_DATE_FORMAT_CONFIG = {
  locale: 'pt-BR',
};

const formatTariffGroupValidityDate = (date: Date, config: { locale: string }) =>
  new Intl.DateTimeFormat(config.locale).format(new Date(date));

export default function TariffGroupsTableItem({
  tariffGroup: {
    id,
    powerDistributionUnit,
    tariffGroupConsumptionCosts,
    tariffGroupConsumptionCosts: {
      energyUsageTariffOnPeak,
      energyUsageTariffOffPeak,
      energyDistributionServiceTariffOnPeak,
      energyDistributionServiceTariffOffPeak,
      energyDistributionServiceTariff,
      energyTariff,
    },
    tariffGroupDemandCosts,
    tariffGroupConsumptionGroup,
    tariffGroupModality,
    tariffGroupValidityEndDate,
    tariffGroupValidityStartDate,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  const tariffGroupDemands = [
    tariffGroupDemandCosts?.onPeak && `PONTA ${tariffGroupDemandCosts.onPeak}`,
    tariffGroupDemandCosts?.offPeak &&
      `FORA PONTA ${tariffGroupDemandCosts.offPeak}`,
    tariffGroupDemandCosts?.all && `NA ${tariffGroupDemandCosts.all}`,
    !tariffGroupDemandCosts ? ' - ' : null,
  ];

  const tariffGroupConsumptionCostsEnergyUsage = [
    tariffGroupConsumptionCosts.energyTariff && `${energyTariff}`,
    tariffGroupConsumptionCosts.energyUsageTariffOnPeak &&
      `PONTA ${energyUsageTariffOnPeak}`,
    tariffGroupConsumptionCosts.energyUsageTariffOffPeak &&
      `FORA PONTA ${energyUsageTariffOffPeak}`,
  ];

  const tariffGroupConsumptionCostsEnergyDistributionService = [
    tariffGroupConsumptionCosts.energyDistributionServiceTariff &&
      `${energyDistributionServiceTariff}`,
    tariffGroupConsumptionCosts.energyDistributionServiceTariffOnPeak &&
      `PONTA ${energyDistributionServiceTariffOnPeak}`,
    tariffGroupConsumptionCosts.energyDistributionServiceTariffOffPeak &&
      `FORA PONTA ${energyDistributionServiceTariffOffPeak}`,
  ];

  return (
    <TableBodyRow dataTestId={`tariff-group-row-id-${id}`}>
      <TableBodyColumn className="font-normal">
        {powerDistributionUnit.powerDistributionUnitLegalName}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {`${formatTariffGroupValidityDate(
          tariffGroupValidityStartDate,
          TARIFF_GROUP_VALIDITY_DATE_FORMAT_CONFIG
        )} - ${formatTariffGroupValidityDate(
          tariffGroupValidityEndDate,
          TARIFF_GROUP_VALIDITY_DATE_FORMAT_CONFIG
        )}`}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {tariffGroupConsumptionGroup}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {TRANSLATE_TARIFF_GROUP_MODALITY_TYPE_TO_PT[tariffGroupModality]}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {tariffGroupConsumptionCostsEnergyUsage.map(
          (item, index) => item && <TariffGroupItem key={index} value={item} />
        )}
      </TableBodyColumn>
      <TableBodyColumn className="font-normal">
        {tariffGroupConsumptionCostsEnergyDistributionService.map(
          (item, index) => item && <TariffGroupItem key={index} value={item} />
        )}
      </TableBodyColumn>
      <TableBodyColumn>
        {tariffGroupDemands.map(
          (item, index) =>
            item && (
              <TariffGroupItem
                key={index}
                value={item}
                className={classNames({ 'text-center': !tariffGroupDemandCosts })}
              />
            )
        )}
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}

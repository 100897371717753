import { Button } from 'ui';

import { omit } from 'lodash';

import TextField from 'ui/form/TextField';

import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';

import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

export type FormFilters = {
  generationUnitLegalName: string;
  commercialAgreementStartValidity: Date;
};

export type FormErrors = {
  generationUnitLegalName?: FieldError;
  commercialAgreementStartValidity?: FieldError;
};

export default function CommercialAgreementsFormFilters({
  errors,
  control,
  register,
  isLoading,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  register: UseFormRegister<FormFilters>;
}) {
  return (
    <div className="grid grid-cols-6 gap-4 pb-6 items-end">
      <TextField
        placeholder="Digitar"
        label="Unidade Geradora"
        id="generationUnitLegalName"
        {...register('generationUnitLegalName')}
        error={errors.generationUnitLegalName?.message}
      />

      <Controller
        control={control}
        name="commercialAgreementStartValidity"
        render={({ field }) => (
          <DatePickerFieldSingleView
            {...omit(field, 'ref')}
            placeholder="MM/AA"
            labelDate="Início de Vigência"
            id="commercialAgreementStartValidity"
            options={{ month: '2-digit', year: '2-digit' }}
            error={errors.commercialAgreementStartValidity?.message}
          />
        )}
      />

      <Button size="sm" type="submit" disabled={isLoading} className="self-end w-22">
        Filtrar
      </Button>
    </div>
  );
}

import classNames from 'classnames';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useHistory } from 'react-router';
import { Button } from 'ui';

export type Page = {
  name: string;
  route: string | null;
  current?: boolean;
};

const Slash = () => (
  <svg
    className="flex-shrink-0 h-5 w-5"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    aria-hidden="true"
  >
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
);

export default function Breadcrumbs({ pages }: { pages?: Page[] }) {
  const history = useHistory();

  return (
    <nav className="flex mt-5" aria-label="Breadcrumb">
      {pages && (
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <Button
                variant="icon"
                onClick={() => history.goBack()}
                className="pl-0 ml-0 pr-0 mr-0 text-gray-dark500 hover:text-gray-dark400"
              >
                <MdKeyboardArrowLeft className="text-lg" />
              </Button>
            </div>
          </li>
          {pages.map((page) => (
            <li
              className="text-gray-dark500 hover:text-gray-dark400"
              key={page.name}
            >
              <div className="flex items-center">
                <Slash />
                <button
                  onClick={() => {
                    if (page.route) history.push(page.route);
                  }}
                  className={classNames('ml-4 text-sm font-medium hover:underline', {
                    'text-primary': page.current,
                  })}
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </button>
              </div>
            </li>
          ))}
        </ol>
      )}
    </nav>
  );
}

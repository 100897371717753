import { Button } from 'ui';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useToastContext from 'ui/hooks/useToast';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import CooperativeFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'cooperatives/components/form/CooperativeFormFields';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  CooperativeUpdated,
  CooperativeUpdateMutationVariables,
  COOPERATIVE_UPDATE_MUTATION,
} from 'cooperatives/graphql/coopertativeUpdateMutation';
import { setFormError } from 'utils/form';
import {
  CooperativeTypename,
  COOPERATIVE_QUERY,
} from 'cooperatives/graphql/coopertativeQuery';
import { DEFAULT_OVERLAY_CONFIG, ToastProps } from 'ui/contexts/overlay/Toast';
import {
  CooperativeDeleteMutationVariables,
  COOPERATIVE_DELETE_MUTATION,
} from 'cooperatives/graphql/coopertativeDeleteMutation';
import {
  FINANCIAL_ACCOUNTS_QUERY,
  FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES,
  FinancialAccountList,
} from 'financialAccounts/graphql/financialAccountsQuery';
import { OverlayConfig, OverlayProps } from 'ui/models/overlay';
import DeleteButton from 'cooperatives/components/DeleteButton';
import { has } from 'lodash';
import { updateCacheById } from 'graphql/apollo/cache';

const DASHBOARD_COOPERATIVES_ROUTE = '/dashboard/business-entities/cooperatives';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Cooperativas',
    route: DASHBOARD_COOPERATIVES_ROUTE,
    current: false,
  },
  {
    name: 'Edição de Cooperativa',
    route: null,
    current: true,
  },
];

const TITLE = 'Edição de Cooperativa';

const UPDATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar atualizar a Cooperativa',
  title: 'Algo deu errado!',
  variant: 'danger',
};
const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao atualizar a Cooperativa.',
};
const FETCH_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a Cooperativa',
};

export default function EditCooperativesPage() {
  const { ConfirmOverlay, showConfirm, closeConfirm } = useConfirm();
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [cooperativeUpdateMutation, { loading: updateLoading }] = useMutation<
    CooperativeUpdated,
    CooperativeUpdateMutationVariables
  >(COOPERATIVE_UPDATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        addToast(UPDATE_ERROR_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      addToast(UPDATE_SUCCESS_TOAST);
      push(DASHBOARD_COOPERATIVES_ROUTE);
    },
  });

  const [cooperativeDeleteMutation, { loading: deleteLoading }] =
    useMutation<CooperativeDeleteMutationVariables>(COOPERATIVE_DELETE_MUTATION, {
      onError() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_ERROR_TOAST);
      },
      onCompleted() {
        closeConfirm();
        setConfirmOverlayProps(DEFAULT_OVERLAY_CONFIG);
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_COOPERATIVES_ROUTE);
      },
    });

  const [
    cooperativeQuery,
    { data, loading: fetchCooperativeLoading, error: fetchCooperativeError },
  ] = useLazyQuery(COOPERATIVE_QUERY, {
    variables: {
      id: id,
    },
  });

  const [
    financialAccountsSelect,
    {
      data: dataFinancialAccounts,
      loading: loadingFinancialAccounts,
      refetch: refetchFinancialAccounts,
    },
  ] = useLazyQuery<FinancialAccountList>(
    FINANCIAL_ACCOUNTS_QUERY,
    FINANCIAL_ACCOUNTS_SELECT_QUERY_VARIABLES
  );

  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm<FormFields>();

  useEffect(() => {
    if (fetchCooperativeError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchCooperativeError]);

  useEffect(() => {
    if (data?.cooperative) {
      FORM_FIELDS.forEach((field) => setValue(field, data.cooperative[field]));
      setValue('financialAccountId', data.cooperative.financialAccount.id);
      setValue(
        'cooperativeHeadquarterId',
        data.cooperative.cooperativeHeadquarter?.id
      );
    }
  }, [data, setValue]);

  useEffect(() => {
    cooperativeQuery();
  }, [cooperativeQuery, id]);

  useEffect(() => {
    refetchFinancialAccounts && refetchFinancialAccounts();

    financialAccountsSelect();
  }, [financialAccountsSelect, refetchFinancialAccounts]);

  const [confirmOverlayProps, setConfirmOverlayProps] = useState<OverlayProps>(
    DEFAULT_OVERLAY_CONFIG
  );

  const isLoading = !!(
    deleteLoading ||
    updateLoading ||
    fetchCooperativeLoading ||
    loadingFinancialAccounts
  );

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (cooperativeUpdateInput) => {
    cooperativeUpdateMutation({
      variables: {
        cooperativeUpdateInput: {
          id: Number(id),
          cooperativeSubscriptionCostCents: Number(
            cooperativeUpdateInput.cooperativeSubscriptionCostCents
          ),
          cooperativeLegalName: cooperativeUpdateInput.cooperativeLegalName,
          cooperativeDocumentId: cooperativeUpdateInput.cooperativeDocumentId,
          cooperativeAddressPostalCode: Number(
            cooperativeUpdateInput.cooperativeAddressPostalCode
          ),
          cooperativeAddressState: cooperativeUpdateInput.cooperativeAddressState,
          cooperativeAddressCity: cooperativeUpdateInput.cooperativeAddressCity,
          cooperativeAddressStreet: cooperativeUpdateInput.cooperativeAddressStreet,
          cooperativeAddressDistrict:
            cooperativeUpdateInput.cooperativeAddressDistrict,
          cooperativeAddressComplement:
            cooperativeUpdateInput.cooperativeAddressComplement,
          financialAccountId: cooperativeUpdateInput.financialAccountId,
        },
      },
    });
  };

  const onConfirmDelete = () =>
    cooperativeDeleteMutation({
      variables: { id: id },
      update(cache) {
        updateCacheById(cache, Number(id), CooperativeTypename);
      },
    });

  const onClickDelete = (overlayConfig: OverlayConfig) => {
    setConfirmOverlayProps({
      ...overlayConfig,
      onConfirm: onConfirmDelete,
    });

    showConfirm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm title={TITLE} breadcrumbPages={BREADCRUMB_PAGES}>
            {data?.cooperative && (
              <>
                <DeleteButton onClick={onClickDelete} disabled={isLoading} />
                <Button type="submit" size="sm" disabled={isLoading}>
                  Salvar
                </Button>
              </>
            )}
            <ConfirmOverlay {...confirmOverlayProps} onCancel={closeConfirm} />
          </DashboardMainHeaderForm>
        }
      >
        {data?.cooperative && (
          <CooperativeFormFields
            headquarters={
              data.cooperative.cooperativeHeadquarter
                ? [
                    {
                      id: data.cooperative.cooperativeHeadquarter?.id,
                      cooperativeLegalName:
                        data.cooperative.cooperativeHeadquarter
                          ?.cooperativeLegalName,
                    },
                  ]
                : []
            }
            financialAccounts={dataFinancialAccounts?.financialAccounts.entries}
            disableHeadquarterSelect={true}
            errors={errors}
            register={register}
            control={control}
          />
        )}
      </Dashboard>
    </form>
  );
}
